/* Ensure the main gallery container has a full-screen height and background image */
.gallery-container {
  height: 100vh;
  background: url('../assets/gallery-bg.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.gallery-content {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  padding-top: 75px;
}

.gallery-text {
  width: 40%; /* Both the text and carousel occupy 40% */
  color: black; /* Ensure all text is black */
  padding-right: 20px; /* Space between text and carousel */
}

.gallery-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold; /* Make the title bold */
  font-family: 'YourFontFamily', sans-serif; /* Replace 'YourFontFamily' with the desired font style */
}

.gallery-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: black; /* Ensure description text is also black */
}

.gallery-carousel {
  width: 40%; /* The carousel also occupies 40% */
}

@media (max-width: 768px) {
  .gallery-container {
    height: 120vh;
  }
  .gallery-content {
    flex-direction: column;
    text-align: center;
  }

  .gallery-text {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .gallery-carousel {
    width: 100%;
  }
}
