.carousel-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background: white;
    border-radius: 10px;
    padding: 10px;
  }
  
  .carousel-main-image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .carousel-main-image video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2rem;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  }
  
  .carousel-control.prev {
    left: 10px;
  }
  
  .carousel-control.next {
    right: 10px;
  }
  
  .expand-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1.5rem;
    padding: 5px;
    cursor: pointer;
    border: none;
    z-index: 3;
  }
  
  .carousel-thumbnails {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    overflow-x: hidden;
    white-space: nowrap;
  }
  
  .thumbnail {
    width: 60px;
    height: 60px;
    background-size: cover;
    background-position: center;
    margin: 0 5px;
    cursor: pointer;
    border: 2px solid transparent;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .thumbnail video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .thumbnail.active {
    border-color: #ffc510;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .close-modal {
    position: absolute;
    top: 20px;
    right: 40px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
  }
  
  .modal-content {
    max-width: 80%;
    max-height: 80%;
    margin: auto;
  }
  
  @media (max-width: 768px) {
    .carousel-container {
      max-width: 100%;
    }
  
    .carousel-main-image {
      height: 200px;
    }
  
    .thumbnail {
      width: 50px;
      height: 50px;
    }
  }
  