.service-card-background-1 {
  background-image: url('../assets/1.png');
  background-size: cover;
  background-position: center;
}

.service-card-background-2 {
  background-image: url('../assets/2.png');
  background-size: cover;
  background-position: center;
}

.service-card-background-3 {
  background-image: url('../assets/3.png');
  background-size: cover;
  background-position: center;
}

.service-card-background-4 {
  background-image: url('../assets/4.png');
  background-size: cover;
  background-position: center;
}

.service-card-background-5 {
  background-image: url('../assets/microcemento2.png');
  background-size: cover;
  background-position: center;
}




.all-services-section {
  display: grid;
  grid-template-columns: 1fr; /* Single column for all screens */
  gap: 2rem;
  background: #ffc510;
  padding: 2rem 0;
}

.all-services-card {
  position: relative;
  height: 25rem; /* Increase the height for more content space */
  overflow: hidden;
  background-size: cover;
  background-position: center;
  transition: background-color 0.3s ease-in-out;
}

.all-services-card::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, transparent, #255751);
  transition: background-color 0.3s ease-in-out;
  z-index: 1; /* Ensure the gradient is behind the content */
}

.all-services-card:hover::before {
  background-color: #255751;
}

.all-services-card-content {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: white;
  z-index: 2; /* Ensure the content is above the gradient */
  transition: transform 0.3s ease-in-out;
  transform: translateY(0); /* Initial state */
}

.all-services-card:hover .all-services-card-content {
  transform: translateY(-300%); /* Move to top left */
}

.all-services-card-icon {
  font-size: 2rem;
  transition: opacity 0.3s ease-in-out;
}

/* Hide title and icon on hover for small screens */
@media (max-width: 768px) {
  .all-services-card:hover .all-services-card-title {
    opacity: 0; /* Hide the title on hover for small screens */
  }

  .all-services-card:hover .all-services-card-icon {
    opacity: 0; /* Hide the icon on hover for small screens */
  }
}

/* No title hiding on hover for larger screens */
.all-services-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  transition: opacity 0.3s ease-in-out;
}

.all-services-card-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  color: white;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 2; /* Ensure the description is above the gradient */
}

.all-services-card:hover .all-services-card-description {
  transform: translateY(0);
}

.all-services-card-icon-bottom {
  margin-top: 15px;
}

.all-services-all-included {
  background-color: #ffc510;
  padding: 6rem 1rem 4rem 1rem;
  
}

.all-services-right-section {
  display: none;
  text-align: right;
  font-size: 18px;
  color: white;
  width: 30%;
}

.all-services-our-services-text {
  font-size: 22px;
  font-weight: 900;
}

@media (min-width: 640px) {
  .all-services-section {
    grid-template-columns: 1fr; /* Maintain single column for small screens */
  }

  .all-services-all-included {
    padding: 4rem 8rem;
  }

  .all-services-right-section {
    display: none;
    text-align: right;
    font-size: 18px;
    color: white;
    width: 30%;
  }

  .all-services-our-services-text {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .all-services-section {
    grid-template-columns: 1fr; /* Maintain single column for medium screens */
  }

  .all-services-all-included {
    padding: 4rem 8rem;
  }

  .all-services-right-section {
    display: none;
  }

  .all-services-our-services-text {
    font-size: 42px;
  }
}

@media (min-width: 1024px) {
  .all-services-section {
    grid-template-columns: 1fr; /* Maintain single column for large screens */
  }

  .all-services-card:hover .all-services-card-content {
    transform: translateY(-200%); /* Move to top left */
  }

  .all-services-all-included {
    padding: 6rem 8rem 4rem 8rem;
  }

  .all-services-right-section {
    display: flex;
  }

  .all-services-our-services-text {
    font-size: 52px;
  }
}

.all-services-button {
  background: white;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  width: 160px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ffc510;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
