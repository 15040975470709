.navbar {
  background-color: white;
  color: black;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: top 0.3s ease-in-out;
}

.navbar-hidden {
  top: -100px; /* Adjust this based on the height of your Navbar */
}

.navbar-logo {
  font-size: 1.25rem;
  font-weight: bold;
}

.burger-icon {
  display: block;
  cursor: pointer;
}

.navbar-menu {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  transition: max-height 300ms ease-in-out;
  overflow: hidden;
  max-height: 0;
  /* Initially hidden */
}

.navbar-menu.open {
  display: flex;
  max-height: 500px;
  /* Adjust based on your content height */
}

.navbar-menu.close {
  max-height: 0;
}

.navbar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center the items on small screens */
}

.navbar-menu li {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  /* Center the content inside the li */
}

.navbar-menu a {
  text-decoration: none;
  color: black;
  display: block;
  width: 100%;
  white-space: nowrap;
  /* Prevent text wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Optional: add ellipsis if text overflows */
}

@media (min-width: 768px) {
  .burger-icon {
    display: none;
  }

  .navbar-menu {
    display: flex;
    position: static;
    flex-direction: row;
    /* Items in a row on larger screens */
    align-items: center;
    max-height: none;
    transition: none;
  }

  .navbar-menu ul {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    /* Items in a row on larger screens */
  }

  .navbar-menu li {
    margin-left: 2rem;
    padding: 0;
    justify-content: flex-start;
    /* Align left on larger screens */
  }

  .navbar-menu a {
    padding: 0.5rem 1rem;
  }
}


/* /////////////////////////////////////////// */
.language-switcher-icon {
  margin-left: 5px;
  /* Ensure the icon in dropdown also has a left margin of 5px */
}



/* about us */
.box-shadow-blur {
    width: 100%;
    height: 75px;
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
}

.about-margin-top {
  margin-top: 75px;
}

.about-button {
  background: #FFC510;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  width: 160px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-weight: 600;
}

.about-description-my {
  margin-top: 10px;
  margin-bottom: 10px;
}

.about-yellow-line {
  background-color: #fbbf24;
  /* Equivalent to bg-yellow-500 in Tailwind */
  border-radius: 0.125rem;
  /* Equivalent to rounded-sm in Tailwind */
}

@media (max-width: 640px) {

  /* Assuming isSmallScreen corresponds to a small screen */
  .about-yellow-line {
    width: 100%;
    /* Equivalent to w-72 in Tailwind */
    height: 2px;
    /* Equivalent to h-[10px] in Tailwind */
  }
}

@media (min-width: 641px) {
  .about-yellow-line {
    width: 10px;
    /* Equivalent to w-[10px] in Tailwind */
    height: 150px;
    /* Equivalent to h-72 in Tailwind */
  }

}

.where-we-operate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
  /* Equivalent to px-5 py-5 in Tailwind */
  background-color: #c5c5c5;
  /* Replace this with your desired color */
  gap: 20px;
}

@media (min-width: 640px) {
  .where-we-operate-container {
    flex-direction: row;
    padding: 4rem 8rem;
  }
}


/* ----------------------------- why choose us? ----------------------------- */
.why-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 8rem;
  gap: 20px;
}

.why-svg-container {
  flex: 1;
  /* Add specific styles to center or size your SVG as needed */
}

.why-text-container {
  flex: 1;
  padding-left: 2rem;
}

.why-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.why-benefits-list {
  list-style-position: outside;
  padding-left: 1rem;
}

.why-benefits-list li {
  margin-bottom: 1rem;
}

.why-benefits-list strong {
  font-weight: bold;
}

/* Responsive layout for smaller screens */
@media (max-width: 900px) {
  .why-content-container {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .why-text-container {
    padding-left: 0;
    padding-top: 1rem;
  }

  .why-title {
    text-align: center;
  }
}

/* services ------------------------------------------- */
.service-card-background-1 {
  background-image: url('../assets/1.png');
  background-size: cover;
  background-position: center;
}

.service-card-background-2 {
  background-image: url('../assets/2.png');
  background-size: cover;
  background-position: center;
}

.service-card-background-3 {
  background-image: url('../assets/3.png');
  background-size: cover;
  background-position: center;
}
/* Add more classes as needed */

.service-card {
  position: relative;
  height: 25rem; /* Increase the height for more content space */
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
}

.service-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, transparent, #255751);
  transition: background-color 0.3s ease-in-out;
  z-index: 1; /* Ensure the gradient is behind the content */
}

.service-card:hover::before {
  background-color: #255751;
}

.card-content {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: white;
  z-index: 2; /* Ensure the content is above the gradient */
  transition: transform 0.3s ease-in-out;
  transform: translateY(0); /* Initial state */
}

.service-card:hover .card-content {
  transform: translateY(-350%); /* Move to top left */
}

.service-card-icon {
  font-size: 2rem;
}

.service-card-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.service-card-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  color: white;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 2; /* Ensure the description is above the gradient */
}

.service-card:hover .service-card-description {
  transform: translateY(0);
}

.services-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  background: #FFC510;
  padding: 2rem 0;
}

.service-card-icon-bottom {
  margin-top: 15px;
}

.services-all-included {
  background-color: #FFC510;
  padding: 4rem 1rem;
}

.right-section {
  display: none;
  text-align: right;
  font-size: 18px;
  color: white;
  width: 30%;
}

.our-services-text {
  font-size: 22px;
  font-weight: 900;
}

@media (min-width: 640px) {
  .services-section {
    grid-template-columns: 1fr; /* 1 column for small screens */
    
  }

  .services-all-included {
    padding: 4rem 8rem;
  }

  .right-section {
    display: none;
    text-align: "right";
    font-size: 18px;
    color: white;
    width: '30%';
  }

  .our-services-text {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .services-section {
    grid-template-columns: 1fr; /* 2 columns for medium screens */
  }

  .services-all-included {
    padding: 4rem 8rem;
  }

  .right-section {
    display: none;
  }

  .our-services-text {
    font-size: 42px;
  }
}

@media (min-width: 1024px) {
  .services-section {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for large screens */
  }

  .service-card:hover .card-content {
    transform: translateY(-320%); /* Move to top left */
  }

  .services-all-included {
    padding: 6rem 8rem 4rem 8rem;
  }

  .right-section {
    display: flex;
  }

  .our-services-text {
    font-size: 52px;
  }
}

.services-button {
  background: white;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  width: 160px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #FFC510;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* //partnerrrrrrrrrrrr new ------------------------------------ */
.partner-section {
  display: grid;
  gap: 20px;
  background: #f8f9fa;
  padding: 4rem 8rem; /* Horizontal padding included */
  background-image: url('../assets/partnerBg.png');
  background-size: cover;
  background-position: center;
}

.partner-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center; /* Centers content horizontally */
  text-align: center; /* Aligns text inside each child element */
}

.partner-header {
  font-size: 50px;
  font-weight: 600;
}

.partner-description {
  font-size: 1rem;
}

.partner-moto {
  font-size: 1rem;
  font-weight: 600; /* Semibold */
}

.partner-visit-button {
  background: #FFC510;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  width: 160px;
  padding: 10px 0; /* Vertical padding only */
  color: white;
  font-weight: 600;
}

.partner-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .partner-section {
    grid-template-columns: 1fr 1fr;
    height: 100vh;
  }

  .partner-content, .partner-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 1023px) {
  .partner-section {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 4rem 2rem; /* Adjusted horizontal padding for smaller screens */
  }

  .partner-logo {
    order: -1; /* Places the logo above the content */
  }
}



/* what materials we use? */
.materials-container {
  background-image: url('../assets/what-materials-we-use.png');
  background-size: cover;
  color: white;
  padding: 4rem 8rem;
}

.materials-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.materials-text {
  line-height: 1.6;
}

.materials-subtitle {
  font-weight: bold;
  margin-top: 20px;
}

.materials-list {
  line-height: 1.6;
  margin-left: 20px;
  list-style-type: disc;
}

.materials-button {
  background: #FFC510;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-weight: 600;
}

@media (max-width: 900px) {
  .materials-container {
    padding: 2rem 1rem;
  }
}